import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import VueScrollmagic from 'vue-scrollmagic'
Vue.use(VueScrollmagic)


Vue.config.productionTip = false;

import API from "@/api/api";
Vue.prototype.API = API;

import global from '../utils/global'
Vue.prototype.global = global

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

Vue.prototype.$moment = moment;

Vue.use(require('vue-shortkey'))

//创建全局 event bus
var EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");