import Vue from "vue";
import Router from "vue-router";
import { getCookie } from "@/utils/utils.js";

Vue.use(Router);

const constantRouterMap = [
  {
    path: "/",
    name: "default",
    meta: { auth: false },
    redirect: "/index",
    component: () => import("../views/Home/homeLayout.vue"),
    children: [
      {
        path: "/index", // 首页
        name: "index",
        meta: { auth: false },
        component: () => import("../views/Home/index"),
      },
      {
        path: "/scene", // 使用场景
        name: "scene",
        meta: { auth: false },
        component: () => import("../views/Home/scene"),
      },
      {
        path: "/aboutUs", // 关于我们
        name: "aboutUs",
        meta: { auth: false },
        component: () => import("../views/Home/aboutUs"),
      },
      {
        path: "/update", // 更新日志
        name: "update",
        meta: { auth: false },
        component: () => import("../views/Home/update"),
      },
      {
        path: "/protocol", // 服务条款
        name: "protocol",
        meta: { auth: false },
        component: () => import("../views/Home/protocol"),
      },
    ],
  },
  {
    path: "/draft",
    name: "draftControl",
    meta: { auth: false },
    redirect: "/draft/control",
    component: () => import("../views/Draft/draftLayout.vue"),
    children: [
      {
        path: "/draft/control",
        name: "control",
        meta: { auth: false },
        component: () => import("../views/Draft/control"),
      },
      {
        path: "/draft/receive",
        name: "receive",
        meta: { auth: false },
        component: () => import("../views/Draft/receive"),
      },
    ],
  },
  {
    path: "/formal",
    meta: { auth: true },
    redirect: "/formal/list",
    component: () => import("../views/Formal/formalLayout.vue"),
    children: [
      {
        path: "/formal/list",
        meta: { auth: true },
        component: () => import("../views/Formal/list"),
      },
      {
        path: "/formal/detail",
        meta: { auth: true },
        component: () => import("../views/Formal/detail"),
      },
      {
        path: "/formal/myOrder",
        meta: { auth: true },
        component: () => import("../views/Formal/myOrder"),
      },
    ],
  },
  {
    path: "/preview/generalChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/generalChart"),
  },
  {
    path: "/preview/marketChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/marketChart"),
  },
  {
    path: "/preview/plateChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/plateChart"),
  },
  {
    path: "/preview/northFundChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/northFundChart"),
  },
  {
    path: "/preview/auctionChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/auctionChart"),
  },
  {
    path: "/preview/financeCompareChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/financeCompareChart"),
  },
  {
    path: "/preview/marginTradeChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/marginTradeChart"),
  },
  {
    path: "/preview/longHuChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/longHuChart"),
  },
  {
    path: "/preview/plateAreaChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/plateAreaChart"),
  },
  {
    path: "/preview/resultsContrastChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/resultsContrastChart"),
  },
  {
    path: "/preview/checkMarketChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/checkMarketChart"),
  },
  {
    path: "/preview/evaluatingDistChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/evaluatingDistChart"),
  },
  {
    path: "/preview/ipoInfoChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/ipoInfoChart"),
  },
  {
    path: "/preview/bigTradeChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/bigTradeChart"),
  },
  {
    path: "/preview/intelligentDiagnosisChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/intelligentDiagnosisChart"),
  },
  {
    path: "/preview/chipDistChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/chipDistChart"),
  },
  {
    path: "/preview/investmentMapChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/investmentMapChart"),
  },
  {
    path: "/preview/companyIntroChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/companyIntroChart"),
  },
  {
    path: "/preview/nuggetsChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/nuggetsChart"),
  },
  {
    path: "/preview/bsSignalChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/bsSignalChart"),
  },
  {
    path: "/preview/financeAnalysisChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/financeAnalysisChart"),
  },
  {
    path: "/preview/stockMineSweepChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/stockMineSweepChart"),
  },
  {
    path: "/preview/plateQuoteChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/plateQuoteChart"),
  },
  {
    path: "/preview/diagnosisValueChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/diagnosisValueChart"),
  },
  {
    path: "/preview/keChuangGraphChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/keChuangGraphChart"),
  },
  {
    path: "/preview/goodwillAnalysisChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/goodwillAnalysisChart"),
  },
  {
    path: "/preview/financingTrackChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/financingTrackChart"),
  },
  {
    path: "/preview/goodwillWarnChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/goodwillWarnChart"),
  },
  {
    path: "/preview/historyBackChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/historyBackChart"),
  },
  {
    path: "/preview/longHuStockChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/longHuStockChart"),
  },
  {
    path: "/preview/similarKChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/similarKChart"),
  },
  {
    path: "/preview/intelliChooseStockChart",
    meta: { auth: false },
    component: () => import("../../businessComp/charts/intelliChooseStockChart"),
  },
  {
    path: "/formal/receive",
    meta: { auth: false },
    component: () => import("../views/Formal/receive"),
  },
  {
    path: "/news",
    meta: { auth: false },
    component: () => import("../views/News"),
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    const position = { x: 0, y: 0 };
    if (savedPosition) {
      return savedPosition;
    }
    if (to.matched.some((m) => m.meta.scrollToTop)) {
      position.x = 0;
      position.y = 0;
    }
    return position;
  },
  routes: constantRouterMap,
});

router.beforeEach((to, from, next) => {
  //根据字段判断是否路由过滤
  if (to.matched.some((record) => record.meta.auth)) {
    if (getCookie("token")) {
      next();
    } else {
      //防止无限循环
      if (to.name === "index") {
        next();
        return;
      }
      next({
        path: "/index",
      });
    }
  } else {
    // 当输入不存在的页面地址时候，返回首页
    if (to.matched.length > 0) {
      next();
    } else {
      next({
        path: "/index",
      });
    }
  }
});

export default router;
