import axios from "axios";
import request from "@/utils/request";
// import request2 from "../utils/request2";
export default {
  // 获取模板页面的数据
  getData: (params) => {
    return axios.get(`https://tanzhiw.com/api/video/videodata/`, { params: params });
  },
  // 获取模板旁白的数据
  getVideoData: (params) => {
    return axios.get(`https://tanzhiw.com/api/video/voicemake/`, { params: params })
  },
  // 录屏服务开始
  recordStart: (domain, params) => {
    return axios.get(`${domain}/api/ffmpeg/recordstart/`, { params: params });
  },
  recordStop: (domain, params) => {
    return axios.get(`${domain}/api/ffmpeg/recordstop/`, { params: params });
  },
  //释放录屏进程
  recordRelease: (domain, params) => {
    return axios.get(`${domain}/api/ffmpeg/recordrelease/`, { params: params });
  },

  releasePcPort: (params) => {
    return axios.get(`https://tanzhiw.com/api/video/releasepcport/`, { params: params })
  },
  combineAudiosWithBgm: (domain, ip, port, data) => {
    return axios.post(`${domain}/api/ffmpeg/combineaudioswithbgm/?ip=${ip}&port=${port}`, data)
  },
  wxpay:{
    // 获取产品信息
    getProduct:()=>{
      return request.get(`https://tanzhiying.com/api/wxpay/getProduct?productType=5`)
    },
    // 微信对外支付
    dtWxPay:(params)=>{
      return request.post(`https://tanzhiying.com/api/wxpay/dtWxPay?corpId=${params.corpId}&productId=${params.productId}&userId=${params.userId}`)
    },
    //通过orderId查询信息
    getOrderInfo(orderId){
      return request.post(`https://tanzhiying.com/api/wxpay/getOrderInfo?orderId=${orderId}`)
    },
    // /wxpay/getOrderInfoList
    getOutOrderInfoList(params) {
      return request.post('https://tanzhiying.com/api/wxpay/getOutOrderInfoList', params)
    },
    queryOutUserAuthInfo(params) {
      return request.post('https://tanzhiying.com/api/wxpay/queryOutUserAuthInfo', params)
    }
  },
  alipay:{
    // 阿里对外支付
    dtAliPay: (params) => {
      return request.post(`https://tanzhiying.com/api/alipay/dtAliPay?corpId=${params.corpId}&productId=${params.productId}&userId=${params.userId}`)
    },
    //通过orderId查询信息
    getOrderInfo(orderId){
      return request.post(`https://tanzhiying.com/api/alipay/getOrderInfo?orderId=${orderId}`)
    },
  },
  // 首页相关接口
  user: {
    // 申请试用
    apply: (params) => {
      return request({
        url: `/master_live/user/apply`,
        method: 'post',
        data: params
      })
    },
    // 获取短信验证码
    captcha: (params) => {
      return request({
        url: `/master_live/user/captcha`,
        method: 'post',
        data: params
      })
    },
    login: (params) => {
      return request({
        url: `/master_live/user/login`,
        method: 'post',
        data: params
      })
    },
    update: (params) => {
      return request({
        url: `/master_live/user/update`,
        method: 'post',
        data: params
      })
    },
    getApply: (params = {}) => {
      return request({
        url: `/master_live/user/getApply`,
        method: 'post',
        data: params
      })
    },
    updateApply: (params = {}) => {
      return request({
        url: `/master_live/user/updateApply`,
        method: 'post',
        data: params
      })
    },
  },
  // 分组
  category: {
    // 创建分组
    create: (params = {}) => {
      return request({
        url: `/master_live/category/create`,
        method: 'post',
        data: params
      })
    },
    // 删除分组
    delete: (params = {}) => {
      return request({
        url: `/master_live/category/delete`,
        method: 'post',
        data: params
      })
    },
    // 编辑分组
    edit: (params = {}) => {
      return request({
        url: `/master_live/category/edit`,
        method: 'post',
        data: params
      })
    },
    // 获取直播间分组
    getList: (params = {}) => {
      return request({
        url: `/master_live/category/getList`,
        method: 'post',
        data: params
      })
    },
    // 获取分组素材
    getCategoryMaterialList: (params = {}) => {
      return request({
        url: `/master_live/category/getCategoryMaterialList`,
        method: 'post',
        data: params
      })
    },
    // 添加素材到分组
    addMaterial2Category: (params = {}) => {
      return request({
        url: `/master_live/category/addMaterial2Category`,
        method: 'post',
        data: params
      })
    },
    // 删除分组下的素材
    deleteMaterialFromCategory: (params = {}) => {
      return request({
        url: `/master_live/category/deleteMaterialFromCategory`,
        method: 'post',
        data: params
      })
    },
  },
  // 直播间
  live: {
    // 创建直播间
    create: (params) => {
      return request({
        url: `/master_live/live/create`,
        method: 'post',
        data: params
      })
    },
    copy: (params) => {
      return request({
        url: `/master_live/live/copy`,
        method: 'post',
        data: params
      })
    },
    // 删除直播间
    delete: (params) => {
      return request({
        url: `/master_live/live/delete`,
        method: 'post',
        data: params
      })
    },
    // 获取直播间列表
    getList: (params) => {
      return request({
        url: `/master_live/live/getList`,
        method: 'post',
        data: params
      })
    },
    // 获取直播间详情
    getRoomDetail: (params) => {
      return request({
        url: `/master_live/live/get/roomId/${params.roomId}`,
        method: 'get',
      })
    },
    // 添加直播间基础元素logo/背景/跑马灯
    addMaterial: (params) => {
      return request({
        url: `/master_live/live/addMaterial`,
        method: 'post',
        data: params
      })
    },
    // 修改直播间基础配置
    update: (params) => {
      return request({
        url: `/master_live/live/update`,
        method: 'post',
        data: params
      })
    },
    // 去直播
    go: (params) => {
      return request({
        url: `/master_live/live/go`,
        method: 'post',
        data: params
      })
    }
  },
  oss: {
    // 文件上传
    upload: (params) => {
      return request({
        url: `/master_live/oss/upload`,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: params
      })
    },
  },
  news: {
    getList: (params) => {
      return request({
        url: `/master_live/news/getList`,
        method: 'post',
        data: params
      })
    },
    toPic: (params) => {
      return request({
        url: `/master_live/news/toPic`,
        method: 'post',
        data: params
      })
    },
  },
  search: {
    getStockSearchList: (params = {}) => {
      return request({
        url: `/master_live/statistic/getStockSearchList`,
        method: 'post',
        data: params
      })
    },
    reportStockSearch: (params = {}) => {
      return request({
        url: `/master_live/statistic/reportStockSearch`,
        method: 'post',
        data: params
      })
    },
    getOssImageInfo: (params) => {
      return request({
        url: `/master_live/search/getOssImageInfo`,
        method: 'post',
        data: params
      })
    },
    getWorkDay: (params) => {
      return request({
        url: `/master_live/search/getWorkDay`,
        method: 'post',
        data: params
      })
    },
    searchImage: (params) => {
      return request({
        url: `/master_live/search/searchImage`,
        method: 'post',
        data: params
      })
    },
    searchTags: (params) => {
      return request({
        url: `/master_live/search/searchTags`,
        method: 'post',
        data: params
      })
    },
    searchNews: (params) => {
      return request({
        url: `/master_live/search/searchNews`,
        method: 'post',
        data: params
      })
    },
  },
  myMaterial: {
    addMaterial: (params) => {
      return request({
        url: `/master_live/mymaterial/addMaterial`,
        method: 'post',
        data: params
      })
    },
    delete: (params) => {
      return request({
        url: `/master_live/mymaterial/delete`,
        method: 'post',
        data: params
      })
    },
    getList: (params) => {
      return request({
        url: `/master_live/mymaterial/getList`,
        method: 'post',
        data: params
      })
    },
    updateMaterial: (params) => {
      return request({
        url: `/master_live/mymaterial/updateMaterial`,
        method: 'post',
        data: params
      })
    },
    sort: (params) => {
      return request({
        url: `/master_live/mymaterial/sort`,
        method: 'post',
        data: params
      })
    },
  },
  material: {
    addMaterial: (params) => {
      return request({
        url: `/master_live/material/addMaterial`,
        method: 'post',
        data: params
      })
    },
    deleteMaterial: (params) => {
      return request({
        url: `/master_live/material/deleteMaterial`,
        method: 'post',
        data: params
      })
    },
    getList: (params) => {
      return request({
        url: `/master_live/material/getList`,
        method: 'post',
        data: params
      })
    },
    updateMaterial: (params) => {
      return request({
        url: `/master_live/material/updateMaterial`,
        method: 'post',
        data: params
      })
    },
  },
  tts:{
    tts: (params) => {
      return request({
        url: `master_live/audio/tts`,
        method: 'post',
        data: params
      })
    },
    getByAudioId: (params) => {
      return request({
        url: `master_live/audio/getByAudioId`,
        method: 'post',
        data: params
      })
    },
    getList: (params) => {
      return request({
        url: `master_live/audio/getList`,
        method: 'post',
        data: params
      })
    },
    delete: (params) => {
      return request({
        url: `master_live/audio/delete`,
        method: 'post',
        data: params
      })
    },
    
  }
};
