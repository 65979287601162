import moment from "moment";
import API from "@/api/api";
import html2canvas from 'html2canvas'
export function cutLive(ele, imgName='未命名') {
  // document.getElementById('preview')
  return new Promise((resove, reject) => {
    html2canvas(ele).then((canvas) => {
      let image = new Image();
      image.src = canvas.toDataURL("image/png");
      image.onload = () => {
          const width = 486
          const height = 414
          const new_canvas = document.createElement("canvas");
          new_canvas.width = width;
          new_canvas.height = height;
          const ctx = new_canvas.getContext("2d");
          ctx.drawImage(image, 56, 40, width, height, 0, 0, width, height);
          // const extension = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
          // console.log(new_canvas.toDataURL("image/" + extension, 1))
          new_canvas.toBlob((blob) => {
              let file = new File([blob], `${imgName}.png`)
              let formData = new FormData()
              formData.append('file', file)
              API.oss.upload(formData).then((res) => {
              if (!res || res.code != 200) {
                    reject()
                  } else {
                      resove(res.data)
                  }
              })
          })
      };
      image.onerror = () => {
          reject('图片加载失败')
      };
  })
  })
}
/*异步等待一段时间*/
export function sleep(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds);
  });
}
// 文本复制
export function copyText(value) {
  return new Promise((resolve) => {
    var input = document.createElement("input"); // js创建一个input输入框
    input.value = value; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    resolve();
  });
}
//验证表单
export async function validateForm(ref) {
  return new Promise((resolve, reject) => {
    ref.validate((valid) => {
      if (valid) {
        resolve();
      }
    });
  });
}
/*获取cookie*/
export function getCookie(name) {
  var regexp = new RegExp("(?:^" + name + "|;\\s*" + name + ")=(.*?)(?:;|$)", "g");
  var result = regexp.exec(document.cookie);
  return result === null ? null : decodeURIComponent(result[1]);
}
/*删除cookie*/
export function delCookie(name, path, domain, secure) {
  if (getCookie(name)) {
    var expires = new Date();
    expires.setTime(expires.getTime() + -10 * 1000);
    domain = domain ? domain : "";
    path = path ? path : "/";
    var newCookie =
      escape(name) +
      "=" +
      escape("") +
      (expires ? "; expires=" + expires.toGMTString() : "") +
      "; path=" +
      path +
      (domain ? "; domain=" + domain : "") +
      (secure ? "; secure" : "");
    document.cookie = newCookie;
  }
}
// 删除所有的cookie
export function clearAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--; ) document.cookie = keys[i] + "=0;expires=" + new Date(0).toUTCString();
  }
}
export function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

export function imgPreloader(url) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => {
      resolve();
    };
    image.onerror = () => {
      reject();
    };
    image.src = url;
  });
}

export function getImageBase64(url) {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = url;
    image.onload = () => {
      resolve(getImageDataURL(image));
    };
    image.onerror = () => {
      reject();
    };
  });
}
function getImageDataURL(image) {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, image.width, image.height);
  const extension = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
  return canvas.toDataURL("image/" + extension, 1);
}

Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

//获得数组前num之和
Array.prototype.getNumArrayTotal = function (num) {
  var total = this.reduce(function (pre, cur, index, arr) {
    if (index > num - 1) {
      return pre + 0;
    }
    return pre + cur;
  });
  return total;
};

Array.prototype.max = function () {
  return Math.max.apply({}, this);
};
Array.prototype.min = function () {
  return Math.min.apply({}, this);
};

export function arrRemove(arr, val) {
  var index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

//输入设计稿的宽度，设定基础字号
export function setRem(designWidth) {
  setRemCore(designWidth);
  window.onresize = function () {
    setRemCore(designWidth);
  };
}

function setRemCore(designWidth) {
  const scale = document.documentElement.clientWidth / designWidth;
  document.documentElement.style.fontSize = 16 * scale + "px";
}

//输入原始数据，给出涨跌幅百分比和绝对值，还有class
export function getRateByData(liData) {
  var sRate = ""; //涨跌幅，带+ -符号和百分比
  var sRateNum = ""; //sRate的值，不带+ -符号和百分比，排序时用，如果停牌就给''
  var className = "";
  var eveClassName = ""; //热点里边框式涨跌样式名  eve_fall,eve_stop,eve_rise
  var classNameNum = ""; //表示没有涨跌幅num和num_stop
  var fNow = liData.fNow;
  var fClose = liData.fClose;
  var eSecStatus = liData.eSecStatus;
  var iTpFlag = liData.iTpFlag < 2 ? 2 : liData.iTpFlag;
  //有停牌状态
  if (eSecStatus == "1") {
    sRate = "停牌";
    sRateNum = "";
    className = "num_stop";
    eveClassName = "eve_stop";
    classNameNum = "num";
    fNow = fClose;
    //没有停牌状态，但fnow为0，我们认为是集合竞价，显示--，fnow如不为0则正常显示
  } else {
    classNameNum = "num";
    if (fNow / 1 === 0) {
      sRate = "--";
      sRateNum = "";
      className = "num_stop2";
      eveClassName = "eve_stop";
      fNow = "--";
    } else {
      var rate = (Math.abs((fNow - fClose) / fClose) * 100).toFixed(2);
      sRateNum = ((fNow - fClose) / fClose) * 100;
      if (fClose > fNow) {
        sRate = "-" + rate + "%";
        className = "num_fall";
        eveClassName = "eve_fall";
      } else if (fClose == fNow) {
        sRate = rate + "%";
        className = "num_stop2";
        eveClassName = "eve_stop";
      } else {
        sRate = "+" + rate + "%";
        className = "num_rise";
        eveClassName = "eve_rise";
      }
      fNow = fNow.toFixed(iTpFlag);
    }
  }
  return {
    fNow: fNow,
    sRate: sRate,
    sRateNum: sRateNum,
    className: className,
    eveClassName: eveClassName,
    classNameNum: classNameNum,
  };
}
// 小数转化为百分比
export function toPercent(point, num = 2) {
  var str = Number(point * 100).toFixed(num);
  str += "%";
  return str;
}
//获取url参数
export function getUrlParams(s) {
  let strs;
  var url = decodeURIComponent(location.href);
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.split("?")[1];
    strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
}
// 解析url
// refers: https://www.sitepoint.com/get-url-parameters-with-javascript/
export function GET_URL_PARAMS(url) {
  const d = decodeURIComponent;
  let queryString = url ? url.split("?")[1] : window.location.search.slice(1);
  const obj = {};
  if (queryString) {
    queryString = queryString.split("#")[0]; // eslint-disable-line
    const arr = queryString.split("&");
    for (let i = 0; i < arr.length; i += 1) {
      const a = arr[i].split("=");
      let paramNum;
      const paramName = a[0].replace(/\[\d*\]/, v => {
        paramNum = v.slice(1, -1);
        return "";
      });
      const paramValue = typeof a[1] === "undefined" ? true : a[1];
      if (obj[paramName]) {
        if (typeof obj[paramName] === "string") {
          obj[paramName] = d([obj[paramName]]);
        }
        if (typeof paramNum === "undefined") {
          obj[paramName].push(d(paramValue));
        } else {
          obj[paramName][paramNum] = d(paramValue);
        }
      } else {
        obj[paramName] = d(paramValue);
      }
    }
  }
  return obj;
}

//切换数据格式，把灯塔数图的格式转成动画库支持的数据格式
export function convertChartData(data) {
  let result;
  let convertedData = [];
  const originalData = data.originalData;
  // console.log("data", data);

  if (["柱形图"].indexOf(data.chartType) > -1) {
    const suffix = originalData.chart1Unit;
    const xKey = Object.keys(originalData.chart1Xdata);

    //看看数据集里有没有小数点超过2位的
    let demicalOverflow = false;
    originalData.chart1Xdata[xKey].map((item, index) => {
      if (!demicalOverflow) {
        const y1Key = Object.keys(originalData.chart1Ydata[0])[0];
        const value1Arr = originalData.chart1Ydata[0][y1Key];
        if (demicalLength(value1Arr[index]) > 2) {
          demicalOverflow = true;
        }
      }
    });
    // console.log("demicalOverflow", demicalOverflow);

    originalData.chart1Xdata[xKey].map((item, index) => {
      const y1Key = Object.keys(originalData.chart1Ydata[0])[0];
      const value1Arr = originalData.chart1Ydata[0][y1Key];
      convertedData.push({
        label: item,
        value1: demicalOverflow ? Math.round(value1Arr[index] * 10) / 10 : value1Arr[index] * 1, //如果有超长的小数点个数则统一截一下
      });
    });
    result = {
      data: convertedData,
      suffix: suffix,
    };
  } else if (["方块图"].indexOf(data.chartType) > -1) {
    const suffix = originalData.chart1Unit;
    const xKey = Object.keys(originalData.chart1Xdata);

    //看看数据集里有没有小数点超过2位的
    let demicalOverflow = false;
    originalData.chart1Xdata[xKey].map((item, index) => {
      if (!demicalOverflow) {
        const y1Key = Object.keys(originalData.chart1Ydata[0])[0];
        const value1Arr = originalData.chart1Ydata[0][y1Key];
        if (demicalLength(value1Arr[index]) > 2) {
          demicalOverflow = true;
        }
      }
    });
    // console.log("demicalOverflow", demicalOverflow);

    originalData.chart1Xdata[xKey].map((item, index) => {
      const y1Key = Object.keys(originalData.chart1Ydata[0])[0];
      const value1Arr = originalData.chart1Ydata[0][y1Key];
      convertedData.push({
        label: item,
        value1: demicalOverflow ? Math.round(value1Arr[index] * 10) / 10 : value1Arr[index] * 1, //如果有超长的小数点个数则统一截一下
      });
    });
    convertedData.map((item) => {
      item.name = "flare." + item.label;
    });
    convertedData.unshift({
      name: "flare",
      value1: null,
    });
    result = {
      data: convertedData,
      suffix: suffix,
    };
  } else if (["饼图", "玫瑰图"].indexOf(data.chartType) > -1) {
    const suffix = originalData.chart1Unit;
    let legend = [];
    let sum = 0;
    originalData.chart1Data.map((item) => {
      sum += item[1] * 1;
    });

    originalData.chart1Data.map((item) => {
      convertedData.push({
        label: ellipsis(item[0], 6),
        fullLabel: item[0],
        value: ((100 * item[1]) / sum).toFixed(1),
      });
    });

    //从大到小排序
    convertedData = convertedData.sort((a, b) => {
      return a.value < b.value ? 1 : -1;
    });
    //获取legend
    convertedData.map((item) => {
      legend.push(item.fullLabel + item.value + "%");
    });
    result = {
      data: convertedData,
      suffix: suffix,
      legend: legend,
    };
  } else if (["折线图", "堆积柱形图", "多柱图", "区间柱图"].indexOf(data.chartType) > -1) {
    originalData.chart1Ydata = originalData.chart1Ydata.slice(0, 3); //将原始数据截取前三条
    const suffix = originalData.chart1Unit;
    const xKey = Object.keys(originalData.chart1Xdata);
    const legend = [];

    originalData.chart1Xdata[xKey].map((item, index) => {
      const y1Key = Object.keys(originalData.chart1Ydata[0])[0];
      const value1Arr = originalData.chart1Ydata[0][y1Key];
      convertedData.push({
        label: item,
        value1: value1Arr[index] * 1,
      });

      //第二条线
      if (originalData.chart1Ydata[1] != undefined) {
        const y2Key = Object.keys(originalData.chart1Ydata[1])[0];
        const value2Arr = originalData.chart1Ydata[1][y2Key];
        convertedData.map((item, index) => {
          item.value2 = value2Arr[index] * 1;
        });
      }

      //第三条线
      if (originalData.chart1Ydata[2] != undefined) {
        const y3Key = Object.keys(originalData.chart1Ydata[2])[0];
        const value3Arr = originalData.chart1Ydata[2][y3Key];
        convertedData.map((item, index) => {
          item.value3 = value3Arr[index] * 1;
        });
      }
    });

    //获取legend
    originalData.chart1Ydata.map((item) => {
      legend.push(Object.keys(item)[0]);
    });

    result = {
      data: convertedData,
      suffix: suffix,
      legend: legend,
    };
  } else if (data.chartType == "K线图") {
    originalData.chart1Xdata.map((item, index) => {
      convertedData.push({
        label: item,
        value: originalData.chart1Ydata[index],
      });
    });
    result = {
      data: convertedData,
    };
  } else if (data.chartType == "表格") {
    convertedData = originalData.rows;
    convertedData.unshift(originalData.headers);
    result = {
      data: convertedData,
    };
  }
  // console.log("result", result);
  return result;
}

//判断小数点后有几位
export function demicalLength(num) {
  if (isInteger(num)) {
    return 0;
  } else {
    return num.toString().split(".")[1].length;
  }
}
//判断是否为整数
function isInteger(obj) {
  return obj % 1 === 0;
}

export function ellipsis(text, maxLength) {
  return text.length > maxLength ? text.substr(0, maxLength) + "..." : text;
}

//防抖
export function debounce(fn, delay = 500) {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
}
export function resBgColor(rgbArr) {
  // 当color值大于128时,color值偏向255,即#ffffff,此时字体颜色应为#000000
  // 当color值小于128时,color值偏向0,即#000000,此时字体颜色应为#ffffff
  var color = 0.213 * rgbArr[0] + 0.715 * rgbArr[1] + 0.072 * rgbArr[2] > 255 / 2;
  return color? '#000000': '#ffffff'
}
export function findTextColor(colorValue) {
    // #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
    const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    var that = colorValue;
    if (/^(rgb|RGB)/.test(that)) {
        // 处理rgb转为数组
        var aColor = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
        return resBgColor(aColor);
    } else if (reg.test(that)) {
        // 处理十六进制色值
        var sColor = colorValue.toLowerCase();
        if (sColor && reg.test(sColor)) {
            if (sColor.length === 4) {
            var sColorNew = "#";
            for (var i = 1; i < 4; i += 1) {
                sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
            }
            sColor = sColorNew;
        }
        //处理六位的颜色值
        var sColorChange = [];
        for (var i = 1; i < 7; i += 2) {
            sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
        }
        return resBgColor(sColorChange);
        } else {
            return false;
        }
    } else {
        return false;
    }
}

/*=====================获取交易日=========================*/
let updateDate;
export function getUpdateDate() {
  return new Promise(async (resolve) => {
    if (updateDate != undefined) {
      resolve(updateDate);
      return;
    }
    const workDay = await API.search.getWorkDay({});
    if (workDay.code == 200) {
      updateDate = workDay.data.date;
      resolve(updateDate);
    } else {
      resolve(moment().format("YYYY-MM-DD"));
    }
  });
}
