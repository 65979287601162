<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    
  },
};
</script>

<style lang="less">
@import "../assets/css/app.less";
</style>
