export default {
    namespaced: true,
    state: {
        detail: {}
    },
    getters: {
        detail: (state) => {
            return  state.detail
        },
    },
    mutations: {
        setDetail: (state, value) => {
            state.detail = value
        }
    },
    actions: {},
}
    