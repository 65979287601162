export default {
    ws: {},
    setWs: function(newWs) {
        this.ws = newWs
    },
    obs: {},
    setObs: function(newObs) {
        this.obs = newObs
    },
    draw: null,
    setDraw: function(newDraw) {
        this.draw = newDraw
    }
}