import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import liveDetails from './liveDetails'
export default new Vuex.Store({
  modules: {
    liveDetails
  }
})
